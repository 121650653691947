<template>
   <div>
      <v-btn outlined color="primary" @click="editbizinfo = true" small><v-icon>mdi-pencil</v-icon> Edit</v-btn>
      <v-dialog v-model="editbizinfo" width="600">
         <v-card class="pa-6 px-10">
            <div class="secondary--text text-h6 font-weight-medium">Edit Business Information</div>
            <v-form lazy-validation ref="form">
               <v-row class="mt-5">
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="text-body-2 mb-1 primary--text">Business Name</div>
                     <v-text-field
                        v-model="form.name"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="text-body-2 mb-1 primary--text">Business Category</div>
                     <v-select 
                        outlined 
                        v-model="form.category" 
                        :items="industries" 
                        :rules="[v=>!!v || 'This field is required']" >
                     </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="text-body-2 mb-1 primary--text">Business Location</div>
                     <v-text-field
                        v-model="form.location"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="text-body-2 mb-1 primary--text">State</div>
                     <v-select 
                        outlined 
                        v-model="form.state" 
                        :items="states" 
                        item-text="name" 
                        :rules="[v=>!!v || 'This field is required']">
                     </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="text-body-2 mb-1 primary--text">City</div>
                     <v-text-field
                        v-model="form.city"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="text-body-2 mb-1 primary--text">Country</div>
                     <v-text-field
                        v-model="form.country"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                     <div class="text-body-2 mb-1 primary--text">Reason for editing</div>
                     <v-textarea
                        v-model="form.reason_for_edit"
                        outlined
                        rows="3" auto-grow
                     ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                  <div class="text-body-2 mb-1 primary--text">Document</div>
                  <v-file-input
                     v-model="form.document"
                     label="Upload document to back up information"
                     outlined
                     accept="image/*, application/pdf">
                  </v-file-input>
               </v-col>
               </v-row>
               <v-btn class="primary" :loading="loading" block large @click="$refs.form.validate() ? editBusiness() : null">Update Information</v-btn>
               <v-btn block large light class="mt-2" depressed @click="editbizinfo = !editbizinfo">Cancel</v-btn>
            </v-form>   
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import {industries} from '@/util/industries'
import { states } from '@/util/statelga'
import { mapActions } from 'vuex'

export default {

   data() {
      return {
         editbizinfo: false,
         industries: industries,
         states: states,
         loading: false,
         form: {}
      }
   },

   methods: {
      ...mapActions('business', ['editbiz']),

      async editBusiness() {
         this.loading = true
         let formData = new FormData()
         formData.append('business_id', this.$route.params.id)
         formData.append('name', this.form.name)
         formData.append('category', this.form.category)
         formData.append('location', this.form.location)
         formData.append('state', this.form.state)
         formData.append('city', this.form.city)
         formData.append('country', this.form.country)
         formData.append('reason_for_edit', this.form.reason_for_edit)
         formData.append('document', this.form.document)
         
         try{
            let response = await this.editbiz(formData)
            this.$toast.success(response.message)
            this.loading = false
            this.$refs.form.reset()
            this.editbizinfo = false
         } catch(error) {
            this.loading = false
            this.$toast.error(error.response.data.message)
         }
      }
   }
}
</script>