<template>
   <div>
      <div class="text-caption actin_cursr text-center" @click="(transferbusiness = true)">
         <v-icon color="secondary" size="30">mdi-credit-card-refund-outline</v-icon> 
         <div class="text-caption text-center"><span>Transfer</span></div>
      </div>
   <v-dialog v-model="transferbusiness" width="500">
      <v-card class="pa-6 px-12 form-colored-bg">
        <div class="">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Transfer</div>
        </div>
        <v-form class="mt-6" lazy-validation v-model="valid" autocomplete="off" ref="transfer">
           <template v-if="diagoption == ''">
               <!--v-btn class="grey pa-3 pa-sm-8 lighten-5 rounded-lg text-center" block depressed @click="bizsettingsdiag = true; diagoption = 'wallet'">
                  <div class="text-subtitle-1 font-weight-bold primary--text"><v-icon color="secondary">mdi-wallet</v-icon> Transfer to Wallet</div>
               </v-btn-->
               <v-btn class="grey lighten-5 mt-3 pa-3 pa-sm-8 rounded-lg text-center" block depressed @click="bizsettingsdiag = true; diagoption = 'bank'">
                  <div class="text-subtitle-1 font-weight-bold primary--text"><v-icon color="secondary">mdi-bank-transfer</v-icon> Transfer to Bank</div>
               </v-btn>
               <v-btn class="mt-3" depressed large @click="$refs.transfer.reset(); transferbusiness = !transferbusiness" block>Close</v-btn>
           </template>
            <!--template v-if="diagoption == 'wallet'">
               <div class="mb-1 primary--text font-weight-medium">Wallet ID</div>
               <v-text-field 
                  v-model="wallet_id" 
                  outlined 
                  oninput="if(this.value.length > 10) return this.value = this.value.slice(0, 10)"
                  required 
                  :rules="[rules.required]">
               </v-text-field>

               <span v-if="wallet_id && wallet_id.length == 10 && form.account_name == ''">Fetching wallet details...
                <v-progress-circular
                 :value="20"
                 color="primary"
                 indeterminate
                 :size="15"
                 :width="2"
                ></v-progress-circular>
               </span>
               
               <div v-if="form.account_name !== ''" class="mb-1 primary--text font-weight-medium">Account Name</div>
               <v-text-field 
                  :value="form.account_name" 
                  v-if="form.account_name !== ''"
                  outlined 
                  required readonly
                  :rules="[rules.required]">
               </v-text-field>

               <div class="mb-1 primary--text font-weight-medium">Enter amount</div>
               <v-text-field prefix="₦" v-model="form.amount" outlined required :rules="[rules.required, rules.min]"></v-text-field>

               <div class="mb-1 primary--text font-weight-medium">Description</div>
               <v-text-field v-model="form.description" outlined required :rules="[rules.required]"></v-text-field>

               <div class="mb-1 primary--text font-weight-medium">Enter Transaction PIN</div>
               <v-text-field v-model="form.transaction_pin" outlined required :rules="[rules.required]" type="password" autocomplete="new-password"></v-text-field>

               <!-v-checkbox 
                  @click="savebeneficiary('wallet')"
                  v-model="addbeneficiary"
                  :disabled="!form.account_number" 
                  label="Add as beneficiary (Staff)" 
                  outlined
                  class="my-0 ben"
               ></v-checkbox->
               <v-btn class="primary" :loading="btnloading" @click="$refs.transfer.validate() ? transfertowallet() : null" block x-large>Transfer to wallet</v-btn>
               <v-btn class="mt-3" depressed large @click="diagoption = ''; $refs.transfer.reset()" block>Cancel</v-btn>
            </template-->
            <template v-if="diagoption == 'bank'">
               <div class="mb-1 primary--text font-weight-medium">Account Number</div>
               <v-text-field 
                  v-model="recipient_account" 
                  oninput="if(this.value.length > 10) return this.value = this.value.slice(0, 10)"
                  outlined required 
                  :rules="[rules.required]">
               </v-text-field>
               
               <div class="mb-1 primary--text font-weight-medium">Select bank</div>
               <v-autocomplete
                  v-model="bank_name"
                  :items="banks"
                  item-text="name"
                  item-value="code"
                  outlined
                  style="mb-1"
                  required
                  :rules="[(v) => !!v || 'This field is required']"
               ></v-autocomplete>

               <span v-if="bank_name !== '' && form.receiver_account_name == ''">Fetching wallet details...
                <v-progress-circular
                 :value="20"
                 color="primary"
                 indeterminate
                 :size="15"
                 :width="2"
                ></v-progress-circular>
              </span>

              <div v-if="form.receiver_account_name !== ''" class="mb-1 primary--text font-weight-medium">Account Name</div>
               <v-text-field 
               :value="form.receiver_account_name" 
               outlined readonly
               v-if="form.receiver_account_name !== ''"
               :rules="[rules.required]"></v-text-field>

               <div class="mb-1 primary--text font-weight-medium">Enter amount</div>
               <v-text-field prefix="₦" v-model="form.amount" outlined required :rules="[rules.required, rules.min]"></v-text-field>

               <div class="mb-1 primary--text font-weight-medium">Description</div>
               <v-text-field v-model="form.description" outlined required :rules="[rules.required]"></v-text-field>

               <div class="mb-1 primary--text font-weight-medium">Enter Transaction PIN</div>
               <v-text-field 
                  v-model="form.transaction_pin" 
                  outlined 
                  required 
                  :rules="[rules.required]"
                  type="password" autocomplete="new-password">
               </v-text-field>

               <!--v-checkbox 
                  @click="savebeneficiary('wallet')"
                  v-model="addbeneficiary"
                  :disabled="!form.account_number" 
                  label="Add as beneficiary (Staff)" 
                  outlined
                  class="my-0 ben"
               ></v-checkbox-->
               <v-btn class="primary" :loading="btnloading" @click="$refs.transfer.validate() ? transfertobank() : null" block x-large>Transfer to bank</v-btn>
               <v-btn class="mt-3" depressed large @click="diagoption = ''; $refs.transfer.reset()" block>Cancel</v-btn>
            </template>
         </v-form>
      </v-card>
    </v-dialog>
   </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
   data() {
      return {
         transferbusiness: false,
         valid: true,
         rules: {
            required: value => !!value || 'This field is required',
            min: value => value >= 10 || "Transfer amount can't go below ₦10",
            number: value => /^[0-9]*$/.test(value) || "Input must be a digit"
         },
         form: {
            business_id: this.$route.params.id,
            receiver_account_name : '',
            account_name: ''
         },
         btnloading: false,
         selectedBusinessId: this.$route.params.id,
         diagoption: '',
         banks: [],
         bank_name: '',
         recipient_account: '',
         wallet_id: ''
      }
   },

   watch: {
      recipient_account: function () {
         if (this.bank_name && this.recipient_account.length == 10) {
            this.validateAccountNumber()
         }
      },

      bank_name: function () {
         if (this.bank_name && this.recipient_account.length == 10) {
            this.validateAccountNumber()
            this.form.bank_name = this.banks.find(
               (x) => x.code === this.bank_name
            ).name
         }
      },

      wallet_id: function () {
         if (this.wallet_id && this.wallet_id.length == 10) {
            this.validateWalletId()
            this.form.account_number = this.wallet_id
         } else if (this.wallet_id && this.wallet_id.length < 10) {
            this.form.account_name = '';
            this.checkbox = false
         }
      },
   },

   methods: {
      ...mapActions('business', ['businesstransfertowallet', 'businesstransfertobank']),
      ...mapActions('transfer', ['getallbanks', 'resolveaccount', 'resolvewalletid']),
      
      async transfertowallet() {
         this.form.business_id = this.selectedBusinessId
         try {
         this.btnloading = true
         let response = await this.businesstransfertowallet(this.form)
         this.$toast.success(response.message)
         this.diagoption = '' 
         this.$refs.transfer.reset()
         this.btnloading = false
         } catch(error) {
            this.$toast.error(error.response.data.message)
            this.btnloading = false  
         } finally {
            this.btnloading = false
         }
      },

      async transfertobank() {
         this.form.business_id = this.selectedBusinessId
         this.form.bank_code = this.bank_name
         this.form.bank_name = this.form.bank_name
         this.form.recipient_account = this.recipient_account
         try {
            this.btnloading = true
            let response = await this.businesstransfertobank(this.form)
            this.$toast.success(response.message)
            this.diagoption = '' 
            this.$refs.transfer.reset()
            this.btnloading = false
         } catch(error) {
            this.$toast.error(error.response.data.message)
            this.btnloading = false  
         } finally {
            this.btnloading = false
         }
      },

      async getBanks() {
         try {
         let response = await this.getallbanks();
         this.banks = response;
         }
         catch (err) {
         this.$toast.error(err.response.data.message)
         }
      },

      async validateAccountNumber() {
         const data = {
            account_number: this.recipient_account,
            bank_code: this.bank_name,
         };
         try {
            let response = await this.resolveaccount(data);
            this.form.receiver_account_name = response.account.name;
            this.form.bank_name = response.account.bank;
         } catch(error) {
            setTimeout(() => {
               this.$toast.error('Please check the account details or your internet connection')
            }, 5000)
         }
      },

      async validateWalletId() {
         const data = {
            wallet_id: this.wallet_id
         };
         try {
            let response = await this.resolvewalletid(data);
            this.form.account_name = response.name;
         } catch (err) {
            setTimeout(() => {
               this.$toast.error('Please verify the wallet ID or check your internet connection.')
            }, 5000)
         }
      },
   },

   mounted() {
      this.getBanks()
   }
}
</script>

<style>
   .actin_cursr {
      cursor: pointer;
   }
</style>