<template>
   <div>
      <v-btn class="primary" @click="showkyc = true"><template v-if="kycstat.length == 0">Upload</template><template v-else>Update</template> Business KYC</v-btn>
      <v-dialog v-model="showkyc" width="800">
        <v-card class="pa-6 px-10">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Business KYC</div>
          <div class="text-body-2 text--secondary">Submit your business documents.</div>
          <v-form class="mt-6" lazy-validation ref="kyc">
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">Country</div>
                <v-text-field
                  v-model="form.country"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">State</div>
                <v-text-field
                  v-model="form.state"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">City</div>
                <v-text-field
                  v-model="form.city"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">LGA</div>
                <v-text-field
                  v-model="form.lga"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">CAC Certificate</div>
                <v-file-input
                  v-model="form.cac_certificate"
                  label="Upload ID document"
                  outlined
                  accept="image/*, application/pdf"
               >
               </v-file-input>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">CAC Application Document</div>
                <v-file-input
                  v-model="form.cac_application_docs"
                  label="Upload ID document"
                  outlined
                  accept="image/*, application/pdf">
               </v-file-input>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">MEMART/Ariticle of Association/MOU</div>
                <v-file-input
                  v-model="form.memart"
                  label="Upload ID document"
                  outlined
                  accept="image/*, application/pdf">
               </v-file-input>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">TIN (Tax Identification number)</div>
                <v-file-input
                  v-model="form.tin"
                  label="Upload ID document"
                  outlined
                  accept="image/*, application/pdf">
               </v-file-input>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">Proof of Address</div>
                <v-file-input
                  v-model="form.proof_of_address"
                  label="Upload ID document"
                  outlined
                  accept="image/*, application/pdf">
               </v-file-input>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-1 primary--text font-weight-medium">Other Business Information</div>
                <v-textarea v-model="form.other_business_info" outlined rows="3" auto-grow></v-textarea>
              </v-col>
            </v-row>     
            <v-divider class="mt-5 mb-10"></v-divider>           
            <v-btn class="primary" v-if="kycstat.length == 0" :loading="loading" @click="$refs.kyc.validate() ? uploadkyc() : null" large>Save</v-btn>
            <v-btn class="primary" v-else :loading="loading" @click="$refs.kyc.validate() ? updatekyc() : null" large>Update</v-btn>
            <v-btn @click="showkyc = !showkyc; loading = false" large light class="ml-3" depressed>Close</v-btn>
          </v-form>
        </v-card>
      </v-dialog>
   </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    kycstat: Object
  },

  data(){
    return {
        showkyc: false,
    form: {
        business_id: this.$route.params.id,
        id: this.kycstat.id
    },
    loading: false,
    rules: {
        required: value => !!value || 'This field is required',
        min: value => value >= 10 || "Transfer amount can't go below ₦10",
        number: value => /^[0-9]*$/.test(value) || "Input must be a digit"
    },
    }
  },

  methods: {
    ...mapActions('business', ['uploadbizkyc', 'updatebizkyc', 'businesskyc']),

    async uploadkyc() {
      this.loading = true
      let formData = new FormData()
      formData.append('business_id', this.form.business_id)
      formData.append('id', this.form.id)
      formData.append('country', this.form.country)
      formData.append('state', this.form.state)
      formData.append('city', this.form.city)
      formData.append('lga', this.form.lga)
      formData.append('proof_of_address', this.form.proof_of_address)
      formData.append('tin', this.form.tin)
      formData.append('memart', this.form.memart)
      formData.append('cac_application_docs', this.form.cac_application_docs)
      formData.append('cac_certificate', this.form.cac_certificate)
      formData.append('other_business_info', this.form.other_business_info)
      try {
        let response = await this.uploadbizkyc(formData)
        this.$toast.success(response.message)
        this.loading = false
        this.$refs.kyc.reset()
        this.getBusinesKYC()
      } catch(error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async updatekyc() {
      this.loading = true
      let formData = new FormData()
      formData.append('business_id', this.form.business_id)
      formData.append('id', this.form.id)
      formData.append('country', this.form.country)
      formData.append('state', this.form.state)
      formData.append('city', this.form.city)
      formData.append('lga', this.form.lga)
      formData.append('proof_of_address', this.form.proof_of_address)
      formData.append('tin', this.form.tin)
      formData.append('memart', this.form.memart)
      formData.append('cac_application_docs', this.form.cac_application_docs)
      formData.append('cac_certificate', this.form.cac_certificate)
      formData.append('other_business_info', this.form.other_business_info)
      try {
        let response = await this.updatebizkyc(formData)
        this.$toast.success(response.message)
        this.loading = false
        this.getBusinesKYC()
      } catch(error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async getBusinesKYC() {
      const data = {
        business_id: this.form.business_id
      }
      try{
        let response = await this.businesskyc(data)
        if(response.data.kyc == null) {
          this.kycdocs = {}
        } else {
          this.kycdocs = response.data.kyc
        }
        
      } catch(error) {

      }
    }
  },

  mounted() {
    if(this.kycstat.length != 0) {
      this.form = this.kycstat
    }
  }
}
</script>