<template>
   <div>
      <v-btn class="primary" @click="addOwner = true">Add new owner</v-btn>
      <v-dialog v-model="addOwner" width="800">
         <v-card class="pa-6 px-10">
            <div class="mb-2 primary--text text-h5 font-weight-medium">Add Business Owners</div>
            <v-form class="mt-6" lazy-validation ref="owner">
               <v-row>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">First Name</div>
                     <v-text-field
                        v-model="form.first_name"
                        outlined
                        :rules="[rules.required]"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">Last Name</div>
                     <v-text-field
                        v-model="form.last_name"
                        outlined
                        :rules="[rules.required]"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">Other Names</div>
                     <v-text-field
                        v-model="form.other_names"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">Email</div>
                     <v-text-field
                        v-model="form.email"
                        outlined
                        required
                        :rules="emailRules"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">ID Document Type</div>
                      <v-select
                        outlined 
                        v-model="form.id_type" 
                        :items="['International Passport', 'Drivers Lisence', 'Voters Card']"
                        ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">Upload Document File</div>
                     <v-file-input
                        v-model="form.id_card"
                        label="Upload ID document"
                        outlined
                        accept="image/*, application/pdf"
                     >
                     </v-file-input>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">Address</div>
                     <v-text-field
                        v-model="form.address"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">State</div>
                     <v-text-field
                        v-model="form.state"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">City</div>
                     <v-text-field
                        v-model="form.city"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">LGA</div>
                     <v-text-field
                        v-model="form.lga"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">Country</div>
                     <v-text-field
                        v-model="form.country"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">Mobile Number</div>
                     <v-text-field
                        v-model="form.phone"
                        outlined
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                     <div class="mb-1 primary--text font-weight-medium">Bvn</div>
                     <v-text-field
                        v-model="form.bvn"
                        outlined
                        required
                        :rules="[rules.number]"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0 pt-12">
                     <v-btn class="primary" :loading="loading" @click="$refs.owner.validate() ? add() : null" large>Save</v-btn>
                     <v-btn @click="addOwner = !addOwner" large light class="ml-3" depressed>Cancel</v-btn>
                  </v-col>
               </v-row>             
            </v-form>
        </v-card>
      </v-dialog>
   </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
   data () {
      return {
         addOwner: false,
         form: {
            business_id: this.$route.params.id
         },
         rules: {
         required: value => !!value || 'This field is required',
            min: value => value >= 10 || "Transfer amount can't go below ₦10",
            number: value => /^[0-9]*$/.test(value) || "Input must be a digit"
         },
         emailRules: [
				(v) => !!v || 'E-mail is required',
				(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
         loading: false,
      }
   },

   methods: {
      ...mapActions('business', ['addBizOwner']),

      async add() {
         this.loading = true
         try {
            let response = await this.addBizOwner(this.form)
            this.$toast.success(response.message)
            this.loading = false
            this.$refs.owner.reset()
         } catch(error) {
            this.$toast.error(error.response.data.message)
            this.loading = false
         }
      }
   }
}
</script>