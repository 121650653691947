<template>
   <div>
      <v-btn outlined small color="primary" @click="showbenetr = true" v-show="benes.length > 0">Pay All</v-btn>
      <v-dialog v-model="showbenetr" width="500">
         <v-card class="pa-6 px-10">
            <div class="secondary--text text-h6 font-weight-medium">Pay Business Staffs</div>
            <v-list nav class="grey rounded-lg bene px-3 mt-10 lighten-5" two-lines>
               <template v-for="(staff, index) in benes">
               <v-list-item  class="rounded-lg white mb-2" link :key="index" @click="(selectUser = true), (selectedUser = staff)" >
                  <v-list-item-avatar>
                  <img alt="" :src="staff.image || require('@/assets/icons/user.png')"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                  <v-list-item-title class="primary--text font-weight-bold" v-text="staff.name"></v-list-item-title>
                  <v-list-item-subtitle><span class="primary--text text-body-1 font-weight-medium">{{ staff.account_number}}</span></v-list-item-subtitle>
                  <v-list-item-subtitle><span class="secondary--text text-body-2">&#8358; {{ staff.salary | formatPrice}}</span></v-list-item-subtitle>
                  </v-list-item-content>
                  <!--v-list-item-action>
                  <v-list-item-title class="primary--text text-subtitle-1"><v-btn depressed fab small color="grey lighten-3" @click="remove(staff)"><v-icon >mdi-close</v-icon></v-btn></v-list-item-title>
                  </v-list-item-action-->
               </v-list-item>
               </template>
            </v-list>
            <v-form class="mt-6" lazy-validation v-model="valid" ref="pin">
               <div class="text-center">
                  <PincodeInput v-model="form.transaction_pin" :length="4" required class="my-3 mb-5" :secure="true" />
               </div>
               
               <div class="mt-5">
                  <v-btn class="primary" :loading="loading" block large @click="pay()">Pay</v-btn>
                  <v-btn block large light class="mt-2" depressed @click="showbenetr = !showbenetr; form.transaction_pin = ''">Cancel</v-btn>
               </div>
            </v-form>
         </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import PincodeInput from 'vue-pincode-input'

export default {
   props: {
      benes: Array
   },

   components: {
      PincodeInput
   },
   
   data() {
      return {
         form: {
            business_id: this.$route.params.id,
            recipients: [],
            staff_id: [],
            transaction_pin: ''
         },
         showbenetr: false,
         loading: false,
         beneficiaries: Object.assign({}, this.benes),
      }
   },

   methods: {
      ...mapActions('business', ['paystaffs']),

      remove(index) {
         this.benes.splice(this.benes.indexOf(index), 1)
      },

      async pay() {
         if(this.form.transaction_pin == '' || this.form.transaction_pin < 4) {
            this.$toast.error("Enter valid Transaction PIN!")
         } else {
            this.loading = true
            
            let ent_ries = Object.entries(this.beneficiaries)
            ent_ries.forEach(([key, value]) => {
               this.form.recipients.push(value['account_number'])
               this.form.staff_id.push(value['id'])
            })

            const data = {
               business_id: this.form.business_id,
               recipients: this.form.recipients,
               staff_id: this.form.staff_id,
               transaction_pin: this.form.transaction_pin
            }

            try{
               let response = await this.paystaffs(data)
               this.$toast.success(response.message)
               this.loading = false
            } catch(error) {
               this.loading =  false
               this.form.transaction_pin = ''
               this.$toast.error(error.response.data.message)
            }
         }
      }
   }
}
</script>