<template>
  <v-container class="px-5 px-md-16">
    <v-container v-if="loading" class="py-15 my-10">
      <div class="py-15 text-center my-15">
        <mini-loader class="text-center"></mini-loader>
      </div>
    </v-container>
    <template v-else>
      <div v-if="loaded">
        <v-row>
          <v-col>
            <v-card elevation="0" class="border-1 pa-10 rounded-lg blue lighten-5">
              <v-row align="center" justify="center"   class="mb-10">
                <v-col>
                  <div class="mb-8">
                    <div class="text-h5 secondary--text font-weight-medium">{{ selectedInfo.name | capitalize }}</div>
                    <div class="text-subtitle-1">{{ selectedInfo.description}}</div>
                  </div>
                </v-col>
                <v-col>
                  <div class="mb-8 float-right">
                    <edit-biz></edit-biz>
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" justify="center"   class="mb-10">
                <v-col align="center" justify="center" cols="6" md="3" sm="6">
                  <div class="text-body-2">Balance</div>
                  <div class="primary--text text-h5 font-weight-medium">&#8358; {{ selectedInfo.balance || '0' | formatPrice }}</div>
                </v-col>
                <v-col align="center" justify="center" cols="6" md="3" sm="6">
                  <div class="text-body-2">Bank Name</div>
                  <div class="primary--text text-h5 font-weight-medium">Peace MFB</div>
                </v-col>
                <v-col align="center" justify="center" cols="6" md="3" sm="6">
                  <div class="text-body-2">Account Number</div>
                  <div class="primary--text text-h5 font-weight-medium">{{ selectedInfo.account_number || '0' }}</div>
                </v-col>
                <v-col align="center" justify="center" cols="6" md="3" sm="6">
                  <div class="text-body-2">Location</div>
                  <div class="primary--text text-h5 font-weight-medium">{{ selectedInfo.location }}</div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" style="margin-top: -50px !important;position: relative;">
          <v-col cols="12" md="7">
            <v-row class="rounded-pill white elevation-2" >
              <v-col cols>
                <transfer></transfer>
              </v-col>
              <v-col cols>
                <div class="text-caption actin_cursr rounded text-center" @click="(bizinfo = true)">
                  <v-icon color="secondary" size="30">mdi-briefcase-account</v-icon> 
                  <div class="text-caption text-center"><span>Business Info.</span></div>
                </div>
              </v-col>
              <v-col cols>
                <div class="text-caption actin_cursr rounded text-center" @click="$router.push({name: 'SelectedBusinessLoan'})">
                  <v-icon color="secondary" size="30">mdi-alarm-panel</v-icon>
                  <div class="text-caption text-center"><span>Loans</span></div>
                </div>
              </v-col>
              <!--v-col cols>
                  <div class="text-caption actin_cursr rounded text-center">
                    <router-link :to="{ name : 'BusinessCards', params: { id : selectedBusinessId }}" style="text-decoration: none">
                      <v-icon color="secondary" size="30">mdi-credit-card-outline</v-icon>
                    </router-link>
                    <div class="text-caption text-center"><span>Card</span></div>
                  </div>
              </v-col-->
              <!--v-col cols>
                <div class="text-caption actin_cursr rounded text-center" @click="(topUpDialog = true)">
                  <v-icon color="secondary" size="30">mdi-bank-transfer</v-icon> 
                  <div class="text-caption text-center"><span>Fund Wallet</span></div>
                </div>
              </v-col-->
              <v-col cols>
                <div class="text-caption actin_cursr rounded text-center" @click="showInvoice = true">
                  <v-icon color="secondary" size="30">mdi-receipt</v-icon> 
                  <div class="text-caption text-center"><span>Invoice</span></div>
                </div>
              </v-col>
              <v-col cols>
                <div class="text-caption actin_cursr rounded text-center" @click="settingsDialogue = true">
                  <v-icon color="secondary" size="30">mdi-cog</v-icon> 
                  <div class="text-caption text-center"><span>Settings</span></div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-15 mx-0 pa-5 rounded-lg elevation-1">
          <v-col cols="12">
            <v-row>
                <v-col>
                  <div class="text-h6 font-weight-medium primary--text">Beneficiaries (Staff)</div>
                </v-col>
                <v-col>
                  <div class="float-right">
                    <pay-all :benes="beneficiaries"></pay-all>
                  </div>
                </v-col>
              </v-row>
          </v-col>
          <v-col class="text-center" cols="2">
            <v-btn class="mx-2" depressed fab color="grey lighten-3" @click="addbene = true"><v-icon>mdi-plus</v-icon></v-btn>
            <div class="text-caption mt-1"><span>Add</span></div>
          </v-col>
          <v-col cols="10">
            <div  v-if="beneficiaries.length == 0" class="text-center">
              No beneficiary was retrieved 
            </div>
            <v-row v-else>
              <v-col class="text-center" cols="2" v-for="(i, index) in beneficiaries" :key="index">
                <v-avatar size="58" style="cursor:pointer" 
                @click="show_selected_beneficiary = true; selected_beneficiary = i;">
                <img :src="i.image || require('../../../assets/icons/user.png')" :lazy-src="i.image || require('../../../assets/icons/user.png')" :alt="i.name"></v-avatar>
                <div class="text-caption mt-1">{{ i.name }}</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col cols="12" md="3">
            <v-card class="pa-5 rounded-lg" elevation="2">
              <div class="text-subtitle-1 font-weight-medium secondary--text">
                Today's Sales
              </div>
              <div class="primary--text text-h6 font-weight-bold">
                {{ sales_history.today.total_transactions }} | &#8358;{{ sales_history.today.total_amount | formatPrice}}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="pa-5 rounded-lg" elevation="2">
              <div class="text-subtitle-1 font-weight-medium secondary--text">
                This Week's Sales
              </div>
              <div class="primary--text text-h6 font-weight-bold">
                  {{ sales_history.this_week.total_transactions }} | &#8358;{{ sales_history.this_week.total_amount | formatPrice}}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="pa-5 rounded-lg" elevation="2">
              <div class="text-subtitle-1 font-weight-medium secondary--text">
                This Month's Sales
              </div>
              <div class="primary--text text-h6 font-weight-bold">
                {{ sales_history.this_month.total_transactions }} | &#8358;{{ sales_history.this_month.total_amount | formatPrice}}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="pa-5 rounded-lg" elevation="2">
              <div class="text-subtitle-1 font-weight-medium secondary--text">
                Overall Sales
              </div>
              <div class="primary--text text-h6 font-weight-bold">
                {{ sales_history.overall.total_transactions }} | &#8358;{{ sales_history.overall.total_amount | formatPrice}}
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-card outlined class="pa-5 mt-15">
          <v-toolbar flat>
            <v-toolbar-title class="text-h6 font-weight-medium primary--text mb-4">Transaction History</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select 
              v-model="transaction"
              :items="['All Transactions', 'Transfer', 'TV Transaction', 'Airtime', 'Data', 'Power', 'Sent Transfer', 'Recieved Transfer']"
              solo dense outlined
              @input="getTransactions()"
            >
            </v-select>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="history"
            hide-default-footer
            :loading="loading"
            class="myTable"
            loading-text="Loading data... Please wait"
          >
          <template v-slot:[`item.amount`]="{ item }">
            <strong class="text-body-2 font-weight-bold">&#8358;{{ item.amount | formatPrice }}</strong>
          </template>
          <template v-slot:[`item.user_balance_before`]="{ item }">
            <strong class="text-body-2 font-weight-bold">&#8358;{{ item.user_balance_before | formatPrice }}</strong>
          </template>
          <template v-slot:[`item.user_balance_after`]="{ item }">
            <strong class="text-body-2 font-weight-bold">&#8358;{{ item.user_balance_after | formatPrice }}</strong>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | formatDate }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status | capitalize }}
          </template>
          </v-data-table>
        </v-card>
        <div class="text-center my-5">
          <v-btn class="primary mt-3" to="/dashboard/business"> <v-icon dark>mdi-arrow-left</v-icon> Back</v-btn>
        </div>
      </div>
      <v-container v-if="!loaded" class="py-15 my-10">
        <div class="py-15 text-center my-15">
          <div>Business not found</div>
          <v-btn class="primary mt-3" to="/dashboard/business"> <v-icon dark>mdi-arrow-left</v-icon> Back</v-btn>
        </div>
      </v-container>
    </template>
      
    <v-dialog v-model="show_selected_beneficiary" width="700">
      <v-card class="pa-6 px-10">
        <v-toolbar flat>
          <v-toolbar-title class="secondary--text text-h5 font-weight-medium">Beneficiary Information</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mx-2" fab small color="gray lighten-5" depressed @click="show_selected_beneficiary = !show_selected_beneficiary"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-form class="mt-6" lazy-validation ref="owner">
              <v-row>
                <v-col cols="12" md="6">
                  <div class="text-body-2 primary--text">Name</div>
                  <div class="text-body-1 primary--text font-weight-medium">{{ selected_beneficiary.name | capitalize }}</div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="text-body-2 primary--text">Account Number</div>
                  <div class="text-body-1 primary--text font-weight-medium">{{ selected_beneficiary.account_number }}</div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="text-body-2 primary--text">Ammount</div>
                    <div class="text-body-1 primary--text font-weight-medium">&#8358;{{ selected_beneficiary.salary | formatPrice }}</div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="text-body-2 primary--text">Email</div>
                  <div class="text-body-1 primary--text font-weight-medium">{{ selected_beneficiary.email }}</div>
                </v-col>
                <v-col cols="12" class="pt-12">
                    <v-btn @click="editselectedbeneficiary = true; selectedbenetoedit = selected_beneficiary.staff_id" large dark class="green mr-2" depressed>Edit</v-btn>
                    <v-btn @click="deletebeneficiary(selected_beneficiary.id)" large dark class="red" depressed>Delete</v-btn>
                </v-col>
              </v-row>             
          </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editselectedbeneficiary" width="600">
      <v-card class="pa-6 px-10">
        <v-toolbar flat>
          <v-toolbar-title class="secondary--text text-h5 font-weight-medium">Edit Beneficiary Information</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mx-2" fab small color="gray lighten-5" depressed @click="editselectedbeneficiary = !editselectedbeneficiary"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-form class="mt-6" lazy-validation ref="editbene">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="text-body-2 font-weight-bold primary--text mb-1">Salaray</div>
                  <v-text-field
                    v-model="form.salary"
                    outlined
                    :rules="[rules.number]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <div class="text-body-2 font-weight-bold primary--text mb-1">On Payroll</div>
                  <v-select 
                    outlined 
                    v-model="form.on_payroll" 
                    :items="['Yes', 'No']">
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <div class="text-body-2 font-weight-bold primary--text mb-1">Suspended</div>
                  <v-select 
                    outlined 
                    v-model="form.suspended" 
                    :items="['Yes', 'No']">
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <div class="text-body-2 font-weight-bold primary--text mb-1">Deactivated</div>
                  <v-select 
                    outlined 
                    v-model="form.deactivated" 
                    :items="['Yes', 'No']">
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <div class="text-body-2 font-weight-bold primary--text mb-1">Bonus</div>
                  <v-text-field
                    v-model="form.bonus"
                    outlined
                    :rules="[rules.number]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <div class="text-body-2 font-weight-bold primary--text mb-1">Tax</div>
                  <v-text-field
                    v-model="form.tax"
                    outlined
                    :rules="[rules.number]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                  <div class="text-body-2 font-weight-bold primary--text mb-1">Pay Cycle</div>
                  <v-select 
                    outlined 
                    v-model="form.pay_cycle" 
                    :items="['Daily', 'Weekly', 'Monthly', 'Yearly']">
                  </v-select>
                </v-col>
                <v-col cols="12" class="">
                  <v-btn @click="$refs.editbene.validate() ? editbeneficiary(selectedbenetoedit) : null" large :loading="beneloading" block dark class="primary mr-2" depressed>Update</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn @click="editselectedbeneficiary = !editselectedbeneficiary" large block light depressed>Close</v-btn>
                </v-col>
              </v-row>             
          </v-form>
      </v-card>
    </v-dialog>

    <v-overlay :value="settingsDialogue">
        <v-card class="pa-15 rounded-lg white" elevation="2">
          <v-btn class="grey pa-3 pa-sm-8 lighten-5 rounded-lg text-center" block depressed @click="bizsettingsdiag = true; diagoption = 'secret_q'">
            <div class="text-subtitle-1 font-weight-bold primary--text">Set secret question</div>
          </v-btn>
          <v-btn class="grey lighten-5 mt-3 pa-3 pa-sm-8 rounded-lg text-center" block depressed @click="bizsettingsdiag = true; diagoption = 'set_pin'">
            <div class="text-subtitle-1 font-weight-bold primary--text">Set new PIN for business</div>
          </v-btn>
        </v-card>
        <div class="text-center mt-5"><v-btn color="secondary" small @click="settingsDialogue = !settingsDialogue" fab top right app><v-icon>mdi-close</v-icon></v-btn></div>
    </v-overlay>
      
    <v-dialog v-model="bizinfo" width="80%">
      <v-card class="pa-6 px-10">
        <v-toolbar flat>
          <v-toolbar-title class="secondary--text text-h6 font-weight-medium">Business Information</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mx-2" fab small color="gray lighten-5" depressed @click="bizinfo = !bizinfo"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-row class="mt-5">
          <v-col cols="4">
            <div class="text-body-2 primary--text">Business Name</div>
            <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.name | capitalize }}</div>
          </v-col>
          <v-col cols="4">
            <div class="text-body-2 primary--text">Business Category</div>
            <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.category | capitalize }}</div>
          </v-col>
          <v-col cols="4">
            <div class="text-body-2 primary--text">Business Location</div>
            <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.location | capitalize }}</div>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="4">
            <div class="text-body-2 primary--text">State</div>
            <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.state | capitalize }}</div>
          </v-col>
          <v-col cols="4">
            <div class="text-body-2 primary--text">City</div>
            <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.city | capitalize }}</div>
          </v-col>
          <v-col cols="4">
            <div class="text-body-2 primary--text">Country</div>
            <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.country | capitalize }}</div>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="4">
            <div class="text-body-2 primary--text">Date Created</div>
            <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.created_at | formatDate }}</div>
          </v-col>
          <v-col cols="4">
            <div class="text-body-2 primary--text">Account Number</div>
            <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.category | capitalize }}</div>
          </v-col>
          <v-col cols="4">
            <div class="text-body-2 primary--text">Account Balance</div>
            <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.balance || '0' | formatPrice }}</div>
          </v-col>
        </v-row>

        <v-divider class="mt-10 mb-5"></v-divider>
          <v-toolbar flat>
          <v-toolbar-title class="secondary--text text-h6 font-weight-medium">Business Documents</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <template v-if="kycdocs.length == 0">
          <v-container class="text-center">Kyc documents not found</v-container>
        </template>
        <template v-else>
          <v-row class="mt-5">
            <v-col cols="3">
              <div class="text-body-2 primary--text">Country</div>
              <div>{{ kycdocs.country || 'null' | capitalize }}</div>
            </v-col>
            <v-col cols="3">
              <div class="text-body-2 primary--text">State</div>
              <div>{{ kycdocs.state || 'null' | capitalize }}</div>
            </v-col>
            <v-col cols="3">
              <div class="text-body-2 primary--text">City</div>
              <div>{{ kycdocs.city || 'null' | capitalize }}</div>
            </v-col>
            <v-col cols="3">
              <div class="text-body-2 primary--text">Local Government Area</div>
              <div>{{ kycdocs.lga || 'null' | capitalize }}</div>
            </v-col>
            <v-col cols="12">
              <div class="text-body-2 primary--text">Other business information</div>
              <div>{{ kycdocs.other_business_info || 'null' | capitalize }}</div>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="3">
              <div class="text-body-2 primary--text">CAC Certificates</div>
              <v-btn color="blue lighten-4" @click="overlay = !overlay; doctoview = 'cac_cert'; bizinfo = false" depressed class="text-body-1 mt-1 font-weight-medium" label text-color="primary"><v-icon class="mr-1" color="secondary">mdi-eye</v-icon> View</v-btn>
            </v-col>
            <v-col cols="3">
              <div class="text-body-2 primary--text">CAC Application Doc.</div>
              <v-btn color="blue lighten-4" depressed @click="overlay = !overlay; doctoview = 'cac_appl'; bizinfo = false" class="text-body-1 mt-1 font-weight-medium" label text-color="primary"><v-icon class="mr-1" color="secondary">mdi-eye</v-icon> View</v-btn>
            </v-col>
            <v-col cols="3">
              <div class="text-body-2 primary--text">Proof of Address</div>
              <v-btn color="blue lighten-4" depressed @click="overlay = !overlay; doctoview = 'poa'; bizinfo = false" class="text-body-1 mt-1 font-weight-medium" label text-color="primary"><v-icon class="mr-1" color="secondary">mdi-eye</v-icon> View</v-btn>
            </v-col>
            <v-col cols="3">
              <div class="text-body-2 primary--text">MEMART/Ariticle of Ass.</div>
              <v-btn color="blue lighten-4" depressed @click="overlay = !overlay; doctoview = 'memart'; bizinfo = false" class="text-body-1 mt-1 font-weight-medium" label text-color="primary"><v-icon class="mr-1" color="secondary">mdi-eye</v-icon> View</v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="3">
              <div class="text-body-2 primary--text">TIN (Tax Identification Number)</div>
              <v-btn color="blue lighten-4" depressed @click="overlay = !overlay; doctoview = 'tin'; bizinfo = false" class="text-body-1 mt-1 font-weight-medium" label text-color="primary"><v-icon class="mr-1" color="secondary">mdi-eye</v-icon> View</v-btn>
            </v-col>
          </v-row>
        </template>
        <v-container class="text-center mt-10"><upload-kyc :kycstat='kycdocs'></upload-kyc></v-container>
        <v-divider class="mt-10 mb-5"></v-divider>
        <v-toolbar flat>
          <v-toolbar-title class="secondary--text text-h6 font-weight-medium">Business Owners</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <template v-if="bizboss.length == 0">
          <v-container class="text-center">Owner details not found</v-container>
        </template>
        <template v-else>
          <v-row class="mt-5" v-for="(index, i) in bizboss" :key="i">
            <v-col cols="12">
              <div class="secondary--text text-body-1 font-weight-medium">Owner {{i+1}} <v-btn class="success ml-3" small depressed @click="updateOwner = !updateOwner; selectedBiz = index">Update</v-btn> <v-btn class="red ml-3" dark small depressed @click="removeOwner(index.id, index)">Remove</v-btn></div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Name</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ index.first_name | capitalize }} {{ index.last_name | capitalize }} {{ index.other_names | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Address</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ index.address | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Phone Number</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ index.phone }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Email</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ index.email }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">State</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ index.state | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">City</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ index.city | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">LGA</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ index.lga | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Country</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ index.country | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">ID Type</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ index.id_type | capitalize }}</div>
            </v-col>
          </v-row>
        </template>
        <v-container class="text-center mt-10"><add-owner></add-owner></v-container>          
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="updateOwner" width="800">
      <v-card class="pa-6 px-10">
        <div class="mb-2 primary--text text-h5 font-weight-medium">Update Business Owner Information</div>
        <v-form class="mt-6" lazy-validation ref="owner">
              <v-row>
                <v-col cols="12" md="6" class="pb-0">
                  <div class="mb-1 primary--text font-weight-medium">First Name</div>
                  <v-text-field
                    v-model="selectedBiz.first_name"
                    outlined
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <div class="mb-1 primary--text font-weight-medium">Last Name</div>
                  <v-text-field
                    v-model="selectedBiz.last_name"
                    outlined
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Other Names</div>
                    <v-text-field
                      v-model="selectedBiz.other_names"
                      outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <div class="mb-1 primary--text font-weight-medium">Email</div>
                  <v-text-field
                    v-model="selectedBiz.email"
                    outlined
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">ID Document Type</div>
                    <v-select
                      outlined 
                      v-model="selectedBiz.id_type" 
                      :items="['International Passport', 'Drivers Lisence', 'Voters Card']"
                      ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-file-input
                      v-model="selectedBiz.id_card"
                      label="Upload ID document"
                      outlined
                      accept="image/*, application/pdf"
                    >
                    </v-file-input>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Address</div>
                    <v-text-field
                      v-model="selectedBiz.address"
                      outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">State</div>
                    <v-text-field
                      v-model="selectedBiz.state"
                      outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">City</div>
                    <v-text-field
                      v-model="selectedBiz.city"
                      outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">LGA</div>
                    <v-text-field
                      v-model="selectedBiz.lga"
                      outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Country</div>
                    <v-text-field
                      v-model="selectedBiz.country"
                      outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Mobile Number</div>
                    <v-text-field
                      v-model="selectedBiz.phone"
                      outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Bvn</div>
                    <v-text-field
                      v-model="selectedBiz.bvn"
                      outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-12">
                    <v-btn class="primary" :loading="loading" @click="$refs.owner.validate() ? updateBizOwnerInfo() : null" large>Update</v-btn>
                    <v-btn @click="updateOwner = !updateOwner" large light class="ml-3" depressed>Close</v-btn>
                </v-col>
              </v-row>             
          </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addbene" width="500">
      <v-card class="pa-6 px-12 form-colored-bg">
        <div class="mb-2 primary--text text-h5 font-weight-medium">Add Beneficiary</div>
        <v-divider></v-divider>
        <v-form class="mt-6" lazy-validation ref="bene">
          <div class="mb-1 primary--text font-weight-medium">Wallet ID</div>
          <v-text-field
            v-model="bene_wallet_id"
            outlined
            required
            :rules="[rules.required]"
          ></v-text-field>
          <span v-if="bene_wallet_id && bene_wallet_id.length == 10 && bene.account_name == ''">Fetching wallet details...
            <v-progress-circular
              :value="20"
              color="primary"
              indeterminate
              :size="15"
              :width="2"
            ></v-progress-circular>
            </span>
            
            <div v-if="bene.account_name !== ''" class="mb-1 primary--text font-weight-medium">Account Name</div>
            <v-text-field 
              :value="bene.account_name" 
              v-if="bene.account_name !== ''"
              outlined 
              required readonly
              :rules="[rules.required]">
            </v-text-field>
          <div class="mb-1 primary--text font-weight-medium">Amount (Salary)</div>
          <v-text-field
            prefix="₦"
            v-model="bene.salary"
            outlined
            required
            :rules="[rules.required, rules.min]"
          ></v-text-field>
          <v-btn
            class="primary" :loading="loadbene" @click="$refs.bene.validate() ? savebeneficiary() : null" block x-large>Save Beneficiary</v-btn>
          <v-btn @click="addbene = !addbene; $refs.bene.reset(); loadbene = false" block large light class="mt-3" depressed>Close</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="topUpDialog" width="500">
      <v-card class="pa-6 px-12 form-colored-bg">
        <div class="mb-2 primary--text text-h5 font-weight-medium">Fund Business Wallet</div>
        <v-divider></v-divider>
        <v-form class="mt-6" lazy-validation ref="topup">
          <div class="mb-1 primary--text font-weight-medium">Enter Amount</div>
          <v-text-field
            prefix="₦"
            v-model="form.amount"
            outlined
            required
            :rules="[rules.required]"
          ></v-text-field>
          <div class="mb-1 primary--text font-weight-medium">Source of Funding</div>
          <v-select
            outlined
            v-model="form.source"
            required
            :rules="[(v) => !!v || 'Please select source of funding']"
            :items="['Card', 'Wallet']"
          ></v-select>
          <v-btn
            class="primary"
            :loading="topuploading"
            @click="$refs.topup.validate() && $refs.topup.validate() && form.source == 'Wallet' ? topUpBusinessWallet()
              : null
            "
            block
            x-large
            >Submit</v-btn
          >
          <v-btn @click="topUpDialog = !topUpDialog; topuploading = false" block large light class="mt-3" depressed>Cancel</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <template v-if="kycdocs.cac_certificate_url != null && doctoview == 'cac_cert'">
        <v-img :src="kycdocs.cac_certificate_url" contain max-height="500" :lazy-src="kycdocs.cac_certificate_url" center>
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
      <template v-else-if="kycdocs.cac_application_docs_url != null && doctoview == 'cac_appl'">
        <v-img :src="kycdocs.cac_application_docs_url" contain max-height="500" :lazy-src="kycdocs.cac_application_docs_url" center>
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
      <template v-else-if="kycdocs.memorandum_url != null && doctoview == 'memart'">
        <v-img :src="kycdocs.memorandum_url" contain max-height="500" :lazy-src="kycdocs.memorandum_url" center>
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
      <template v-else-if="kycdocs.tin_url != null && doctoview == 'tin'">
        <v-img :src="kycdocs.tin_url" contain max-height="500" :lazy-src="kycdocs.tin_url" center>
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
      <template v-else-if="kycdocs.proof_of_address_url != null && doctoview == 'poa'">
        <v-img :src="kycdocs.proof_of_address_url" contain max-height="500" :lazy-src="kycdocs.proof_of_address_url" center>
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
      <template v-else>
        <v-card class="pa-15 rounded-lg white" elevation="2">
          <div class="text--secondary">
            Document not found, please upload document to continue.
          </div>
        </v-card>
      </template>

      <div class="text-center mt-5"><v-btn color="secondary" small @click="overlay = !overlay; bizinfo = true" fab top right app><v-icon>mdi-close</v-icon></v-btn></div>
    </v-overlay>

    <v-dialog v-model="bizsettingsdiag" width="500">
      <template v-if="diagoption == 'secret_q'">
        <v-card class="pa-6 px-12 form-colored-bg">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Set secret question</div>
          <v-divider></v-divider>
          <v-form class="mt-6" lazy-validation ref="qa">
            <div class="mb-1 primary--text font-weight-medium">Question</div>
            <v-text-field
              v-model="form.question"
              outlined
              required
              :rules="[rules.required]"
            ></v-text-field>
            <div class="mb-1 primary--text font-weight-medium">Answer</div>
            <v-text-field
              v-model="form.answer"
              outlined
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-btn
              class="primary" :loading="settingsloading" @click="$refs.qa.validate() ? setscquestion() : null" block x-large>Update</v-btn>
            <v-btn @click="bizsettingsdiag = !bizsettingsdiag; settingsloading = false" block large light class="mt-3" depressed>Cancel</v-btn
            >
          </v-form>
        </v-card>
      </template>
      <template v-else-if="diagoption == 'set_pin'">
        <v-card class="pa-6 px-12 form-colored-bg">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Set new PIN for business</div>
          <v-divider></v-divider>
          <v-form class="mt-6" lazy-validation ref="pin">
              <div class="text-center">
                <PincodeInput v-model="form.transaction_pin" :length="4" class="my-3 mb-5" :secure="true" />
              </div>
            <v-btn class="primary" :loading="settingsloading" @click="$refs.pin.validate() ? setbizpin() : null" block x-large>Set PIN</v-btn>
            <div class="text-center mt-3"><v-btn class="text-center white" depressed v-if="selectedInfo.secret_question != null" @click="diagoption = 'reset_pin'">Reset PIN</v-btn></div>
            <v-btn @click="bizsettingsdiag = !bizsettingsdiag; settingsloading = false" block large light class="mt-3" depressed>Cancel</v-btn
            >
          </v-form>
        </v-card>
      </template>
      <template v-else>
        <v-card class="pa-6 px-12 form-colored-bg">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Reset business PIN</div>
          <v-divider></v-divider>
          <v-form class="mt-6" lazy-validation ref="rpin">
            <div class="mb-1 primary--text font-weight-medium">Secret Question</div>
            <v-text-field
              v-model="selectedInfo.secret_question"
              outlined readonly
              required
            ></v-text-field>
            <div class="mb-1 primary--text font-weight-medium">Secret Answer</div>
            <v-text-field
              v-model="form.answer"
              outlined
              required
            ></v-text-field>
              <div class="text-center">
                <div class="mb-1 primary--text font-weight-medium">New PIN</div>
                <PincodeInput v-model="form.transaction_pin" :length="4" class="mb-5" :secure="true" />
              </div>
            <div class="mb-1 primary--text font-weight-medium">OTP</div>
            <v-text-field
              v-model="form.otp"
              outlined
              required
            ></v-text-field>
            <v-btn class="primary" :loading="settingsloading" @click="$refs.rpin.validate() ? resetbizpin() : null" block x-large>Reset PIN</v-btn>
            <v-btn @click="bizsettingsdiag = !bizsettingsdiag; settingsloading = false" block large light class="mt-3" depressed>Close</v-btn
            >
          </v-form>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="showInvoice" width="500">
      <v-card class="pa-6 px-12 form-colored-bg">
        <div class="primary--text text-h5 font-weight-medium py-15 text-center ma-15">Comming Soon!</div>
        <v-btn @click="showInvoice = !showInvoice" block large light class="mt-3" depressed>Close</v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import MiniLoader from '@/components/MiniLoader.vue'
import AddOwner from '@/components/business/AddOwner.vue'
import UploadKyc from '@/components/business/UploadKyc.vue'
import PincodeInput from 'vue-pincode-input'
import Transfer from '@/components/business/Transfer'
import EditBiz from '@/components/business/EditBiz'
import PayAll from '@/components/business/PayAll'

import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    MiniLoader,
    AddOwner,
    UploadKyc,
    PincodeInput,
    Transfer,
    EditBiz,
    PayAll
  },
  layout: 'dashboard',
  transition: 'default',
  data() {
    return {
      createNew: '',
      topUpDialog: false,
      settingsDialogue: false,
      inviteDialog: false,
      inviteDialogAgent: false,
      loading: true,
      loaded: false,
      valid: true,
      rules: {
        required: value => !!value || 'This field is required',
        min: value => value >= 10 || "Transfer amount can't go below ₦10",
        number: value => {
          if(!value) return true
          if(!isNaN(value)) return true
          return "Input must be a digit"
        }
      },
      emailRules: [
			  (v) => !!v || 'E-mail is required',
				(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
      form: {
        business_id: this.$route.params.id
      },
      bizinfo: false,
      settings: false,
      card: false,
      selectUser: false,
      selectedUser: [],
      p: [],
      g: [],
      a: [],
      searchedMember: false,
      s: [],
      selectedBusinessId: this.$route.params.id,
      selectedInfo: [],
      headers: [
        { text: 'Description', value:  'activity' },
        { text: 'Transaction', value:  'type' },
        { text: 'Balance before', value:  'user_balance_before' },
        { text: 'Amount', value:  'amount' },
        { text: 'Balance after', value:  'user_balance_after' },
        { text: 'Status', value:  'status' },
        { text: 'Reference', value:  'reference' },
        { text: 'Date', value:  'created_at' }
      ],
      beneficiaries: [],
      history: [],
      transaction: 'All Transactions',
      addbeneficiary: false,
      topuploading: false,
      kycloading: false,
      addbene: false,
      loadbene: false,
      bene: {
        business_id: this.$route.params.id,
        receiver_account_name : '',
        account_name: ''
      },
      bizboss: [],
      updateOwner: false,
      selectedBiz: [],
      bizdoc: [],
      kycdocs: [],
      overlay: false,
      doctoview: '',
      bizsettingsdiag: false,
      settingsloading: false,
      diagoption: '',
      showInvoice: false,
      bene_wallet_id: '',
      checkbox: false,
      selected_beneficiary: '',
      show_selected_beneficiary: false,
      selected_beneficiary_account: '',
      sales_history: [],
      editselectedbeneficiary: false,
      selectedbenetoedit: '',
      amtToUpdate: '',
      beneloading: false
    }
  },

  computed: {
    ...mapGetters('auth', ['user'])
  },

  watch: {
    bene_wallet_id: function () {
         if (this.bene_wallet_id && this.bene_wallet_id.length == 10) {
            this.validateWalletId()
            this.bene.account_number = this.bene_wallet_id
         } else if (this.bene_wallet_id && this.bene_wallet_id.length < 10) {
            this.bene.account_name = '';
            this.checkbox = false
         }
      },
  },

  filters: {
    capitalize: function (value) {
        if (!value) return ''
            value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },

  methods: {
    ...mapActions('business', 
      ['bizbeneficiaries', 'singlebusiness', 'businesstransfertobank', 
      'transactionhistory', 'savebenefit', 'removeO', 'updateO', 'businesskyc',
      'setsecretq', 'setnewbizpin', 'resetnewbizpin', 'deletebene', 'editbene']),

    ...mapActions('transfer', ['resolvewalletid']),
    
    async removeOwner(id, index) {
      const data = {
        id: id
      } 
      try {
        let response = await this.removeO(data)
        this.$toast.success(response.message)
        this.bizboss.splice(this.bizboss.indexOf(index), 1)
      } catch(error) {
        this.$toast.error(error.response.data.message)
      }
    },

    async updateBizOwnerInfo() {
      this.loading = true
      this.selectedBiz.business_id = this.selectedBusinessId
      try {
        let response = await this.updateO(this.selectedBiz)
        this.$toast.success(response.message)
        this.loading = false
      } catch (error) {
        this.$toast.error(error.response.data.message)
        this.loading = false
      }
    },

    async getBusinessBeneficiaries() {
      const data = {
        business_id  : this.selectedBusinessId
      }
      try {
        let res = await this.bizbeneficiaries(data)
        this.beneficiaries = res.data
      } catch(err) {

      }
    },

    async getSelectedBusiness() {
      const data = {
        id: this.selectedBusinessId
      }
      try {
        let res = await this.singlebusiness(data)
        this.selectedInfo = res.data
        if(this.selectedInfo != '' || this.beneficiaries != '') {
          this.loading = false
          this.loaded = true
          this.bizboss = this.selectedInfo.owners,
          this.sales_history = this.selectedInfo.sales_history
        }
      } catch(error) {
        this.loading = false
        this.loaded = false
      }
    },

    async getTransactions() {
      const data = {
        business_id: this.selectedBusinessId
      }
      let res
      switch (this.transaction) {       
        case 'Transfer':
          res = await this.transactionhistory(data)
          this.history = res.data
        break;
        case 'TV Transaction':
          res = await this.transactionhistory(data)
        break;
        case 'Airtime':
          res = await this.transactionhistory(data)
        break;
        case 'Data':
          res = await this.transactionhistory(data)
        break;
        case 'Power':
          res = await this.transactionhistory(data)
        break;
        case 'Sent Transfer':
          res = await this.transactionhistory(data)
          this.history = res.history
        break;
        case 'Recieved Transfer':
          res = await this.transactionhistory(data)
          this.history = res.history
        break;
        default:
          res = await this.transactionhistory(data)
          this.history = res.data
        break;
      }
      
    },
    
    async savebeneficiary() {
      this.bene.business_id = this.selectedBusinessId
      this.loadbene = true

      try{
        let response = await this.savebenefit(this.bene)
        this.$toast.success(response.message)
        this.loadbene = false
        this.getBusinessBeneficiaries()
        this.addbene = false
        this.bene.reset()
      } catch(error) {
          if(error.response && error.response.status === 422) {
            this.$toast.error(error.response.data.data.account_number ? error.response.data.data.account_number[0] : '') 
          } else {
            this.$toast.error(error.response.data.message)
          }
        this.loadbene = false
      }
    },

    async deletebeneficiary(id){
      const data = {
        id: id
      }
      try{
        let response = await this.deletebene(data)
        this.$toast.success(response.message)
        this.getBusinessBeneficiaries()
        this.show_selected_beneficiary = false
      } catch(error) {
        this.$toast.error(error.response.data.message)
      }
    },

    async editbeneficiary(id, amt) {
      this.form.staff_id = id
      this.beneloading = true
      try{
        let response = await this.editbene(this.form)
        this.$toast.success(response.message)
        this.beneloading = false
        this.select = this.form.salary
        this.getBusinessBeneficiaries()
        this.editselectedbeneficiary = false
        this.selected_beneficiary.salary = this.form.salary
      } catch(error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async topUpBusinessWallet() {
      this.topuploading = true
        const data = {
          business_id: this.selectedInfo.id,
          amount: this.selectedInfo.projected_saving
        }
        var res_p = this.$store.dispatch('savings/fundbusinesswallet', data)
      try {
        let res = await res_p
        this.$toast.success(res.message)
          this.loading = this.topUpDialog = false
          this.getSelectedSavings()
          this.$refs.topup.reset()
      } catch(error) {
        this.$toast.error(error.response.data.message)
        this.loading = false
      }
    },

    async getBusinesKYC() {
      const data = {
        business_id: this.selectedBusinessId
      }
      try{
        let response = await this.businesskyc(data)
        if(response.data.kyc == null) {
          this.kycdocs = []
        } else {
          this.kycdocs = response.data.kyc
        }
        
      } catch(error) {

      }
    },

    async setscquestion() {
      this.settingsloading = true
      try{
        let response = await this.setsecretq(this.form)
        this.$toast.success(response.message)
        this.settingsloading = false
        this.$refs.qa.reset()
      } catch(error) {
        this.settingsloading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async setbizpin() {
      this.settingsloading = true
      try{
        let response = await this.setnewbizpin(this.form)
        this.$toast.success(response.message)
        this.settingsloading = false
        this.$refs.pin.reset()
      } catch(error) {
        this.settingsloading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async resetbizpin() {
      this.settingsloading = true
      try{
        let response = await this.resetnewbizpin(this.form)
        this.$toast.success(response.message)
        this.settingsloading = false
        this.$refs.rpin.reset()
      } catch(error) {
        this.settingsloading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async validateWalletId() {
         const data = {
            wallet_id: this.bene_wallet_id
         };
         try {
            let response = await this.resolvewalletid(data);
            this.bene.account_name = response.name;
         } catch (err) {
            setTimeout(() => {
               this.$toast.error('Please verify the wallet ID or check your internet connection.')
            }, 5000)
         }
      },
  },

  mounted() {
    this.getTransactions()
    this.getSelectedBusiness()
    this.getBusinessBeneficiaries()
    this.form.user_id = this.user.id
    this.form.source = "Wallet"
    this.getBusinesKYC()
  },
}
</script>
<style lang="scss" scoped>
.next-card {
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
  }
}

.ben .form-colored-bg .v-input__slot {
  background-color: white !important;
}

.single-savings-card {
  border: 0.5px solid #d9dbfa;
  box-shadow: 0px 2px 2px rgba(171, 153, 153, 0.25) !important;
  box-sizing: border-box;
  border-radius: 5px;
  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 132.5%;
    color: #c1a13d;
  }
  &:hover {
    background: #ffffff;
    border: 0.5px solid #484dc7;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px 9px rgba(219, 200, 200, 0.25);
    border-radius: 5px;
  }
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 105.5%;
    color: #2f2d2d;
    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 132.5%;
      color: #171b70;
    }
  }
  .v-card {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 132.5%;
    color: #171b70;
  }
}
.v-progress-linear {
  border-radius: 10px !important;
}
.white {
  background-color: white !important;
  border-color: white;
}
.days-left {
  height: 48px;
  width: 48px;
  border-radius: 40px;
  background: #c4c4c4;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 132.5%;
  /* or 21px */

  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
}
.v-tabs-slider {
  background-color: #c1a13d
}
.actin_cursr {
  cursor: pointer;
}
.avatars {
  transform: scale(-1, 1);
  display: inline-block;
}
.avatar {
  position: relative;
  display: inline-block;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}
.outl {
  background-color: #EEEEEE;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.myTable table thead, .v-data-table-header {
  background-color: #1E88E5 !important;
  color: #FFFFFF;
}
.avatar:not(:first-child) {
  margin-left: -10px;
}
.avatar img {
  display: block;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px !important
}
.loading {
  height: calc(100vh);
  position: absolute;
  display: block;
  width: 100%;
  top: 40%;
}
</style>
